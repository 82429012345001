var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page,
                title: _vm.$t("Add")
              },
              on: {
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "selection-change": _vm.selectionChange,
                "refresh-change": _vm.refreshChange,
                "row-save": _vm.handleSave,
                "row-update": _vm.handleUpdate,
                "row-del": _vm.handleDel
              },
              scopedSlots: _vm._u([
                {
                  key: "menu",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var size = ref.size
                    var type = ref.type
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-data-analysis",
                            size: size,
                            type: type
                          },
                          on: {
                            click: function($event) {
                              return _vm.goReport(row)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("Check the report")) +
                              "\n                "
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "menuForm",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var type = ref.type
                    return [
                      type == "add"
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-circle-plus-outline",
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.crud.rowSave()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("Hold")) + "\n                "
                              )
                            ]
                          )
                        : _vm._e(),
                      type == "edit"
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-circle-check",
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.crud.rowUpdate()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("Hold")) + "\n                "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-close",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.crud.closeDialog()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("Cancel")) +
                              "\n                "
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "menuLeft" },
                [
                  _c("importBtn", {
                    attrs: {
                      outApi: this.proApis.CORPUSERSEXPORT,
                      innerAPI: this.proApis.CORPUSERSIMPORT,
                      API: this.proApis.CORPUSERSIMPORTTEMPLATE
                    },
                    on: { refresh: _vm.refreshData }
                  })
                ],
                1
              ),
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("BELONGING DEPARTMENT"),
                        size: "small"
                      },
                      on: { change: _vm.getID },
                      model: {
                        value: _vm.departmentId,
                        callback: function($$v) {
                          _vm.departmentId = $$v
                        },
                        expression: "departmentId"
                      }
                    },
                    _vm._l(_vm.depmentList, function(value) {
                      return _c("el-option", {
                        key: value.id,
                        attrs: { label: value.name, value: value.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("WORKSHOP"),
                        size: "small"
                      },
                      on: { change: _vm.getWorkShopId },
                      model: {
                        value: _vm.workShopId,
                        callback: function($$v) {
                          _vm.workShopId = $$v
                        },
                        expression: "workShopId"
                      }
                    },
                    _vm._l(_vm.workShopList, function(value) {
                      return _c("el-option", {
                        key: value.id,
                        attrs: { label: value.name, value: value.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("TEAM"),
                        size: "small"
                      },
                      model: {
                        value: _vm.groupId,
                        callback: function($$v) {
                          _vm.groupId = $$v
                        },
                        expression: "groupId"
                      }
                    },
                    _vm._l(_vm.groupList, function(value) {
                      return _c("el-option", {
                        key: value.id,
                        attrs: { label: value.name, value: value.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("JOB"),
                        size: "small"
                      },
                      model: {
                        value: _vm.postId,
                        callback: function($$v) {
                          _vm.postId = $$v
                        },
                        expression: "postId"
                      }
                    },
                    _vm._l(_vm.postList, function(value) {
                      return _c("el-option", {
                        key: value.id,
                        attrs: { label: value.name, value: value.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: _vm.$t("STATUS"),
                        size: "small"
                      },
                      model: {
                        value: _vm.model,
                        callback: function($$v) {
                          _vm.model = $$v
                        },
                        expression: "model"
                      }
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { label: _vm.$t("ON THE JOB"), value: "0" } },
                        [_vm._v(_vm._s(_vm.$t("ON THE JOB")))]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: _vm.$t("QUIT"), value: "1" } },
                        [_vm._v(_vm._s(_vm.$t("QUIT")))]
                      ),
                      _c(
                        "el-option",
                        {
                          attrs: {
                            label: _vm.$t("TO BE CONFIRMED"),
                            value: "-1"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("TO BE CONFIRMED")))]
                      )
                    ],
                    1
                  ),
                  _c("el-input", {
                    staticStyle: {
                      display: "inlie-block",
                      width: "200px",
                      marginRight: "5px"
                    },
                    attrs: {
                      placeholder: _vm.$t("姓名或工号"),
                      size: "small",
                      clearable: ""
                    },
                    model: {
                      value: _vm.nameOrCod,
                      callback: function($$v) {
                        _vm.nameOrCod = $$v
                      },
                      expression: "nameOrCod"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini"
                      },
                      on: { click: _vm.search }
                    },
                    [_vm._v(_vm._s(_vm.$t("SEARCH")) + "\n                ")]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }