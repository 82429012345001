import request from '@/router/axios';
import {businessUrl} from "@/config/env"
export const getTree = () => request({
    url: businessUrl + "",
    method: "post",
    data: {
        baseSpecSheetId: ""
    }
})
export const list = (pageNo,pageSize,departmentId,workShopId,groupId,postId,status,nameOrCode) => request({
    url: businessUrl + "corpUsers/selectUserDeptPage",
    method: "post",
    data: {
        pageNo,pageSize,departmentId,workShopId,groupId,postId,status,nameOrCode
    }
})
export const add = (row,parentId) => request({
    url: businessUrl + "corpUsers/insert",
    method: "post",
    data: {
        ...row,
        parentId
    }
})
export const remove = (id) => {
    return request({
    url: businessUrl + "corpUsers/delete",
    method: "delete",
    params: {
        id: String(id)
    }
    })
}
export const edit = (row) => request({
    url: businessUrl + "corpUsers/updateByPrimaryKey",
    method: "put",
    data: {
        ...row
    }
})
//根据岗位/机器编码获取人员
export const getpepoelChange = (cod,postId) => request({
    url: businessUrl + "machine/selectNameByUserChange",
    method: "post",
    data: {
        cod,postId,time: "2021-1-24"
    }
})
//根据班次获取班组和员工
export const getClassAndPepole = (suShiftId,code,postId) => request({
    url: businessUrl + "machine/selectOneByUserChange",
    method: "post",
    data: {
        suShiftId,code,postId,time: "2021-1-24"
    }
})
//导出
export const exportExcl = () => request({
    url: businessUrl + "corpUsers/export",
    method: "get",
    responseType: "blob"
})
export const selectTeamByWorkShopId = (workShopId) => request({
    url: businessUrl + "department/selectGroupName",
    method: "post",
    params: {
        workShopId
    }
})